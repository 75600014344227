'use client';

export async function sendClaimToApi(pool: number, chainid: number | undefined, address: `0x${string}` | undefined, type: number ): Promise<any> {
    const response = await fetch('/api/claim/', {
        method: 'POST',
        body: JSON.stringify({ pool, chainid, address, type }),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Error sending payment data to API');
    }

    return await response.json();
}

export async function sendPoolPayoutToApi(pool: number, chainid: number | undefined, address: `0x${string}` | undefined ): Promise<any> {
    const response = await fetch('/api/pools/payout/', {
        method: 'POST',
        body: JSON.stringify({ pool, chainid, address }),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Error sending payment data to API');
    }

    return await response.json();
}

export async function sendAnglePoolActivateToApi(pool: number, chainid: number | undefined, address: `0x${string}` | undefined ): Promise<any> {
    const response = await fetch('/api/pools/activate-angel/', {
        method: 'POST',
        body: JSON.stringify({ pool, chainid, address }),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Error sending payment data to API');
    }

    return await response.json();
}

export async function sendTransactionHash(transactionHash: any, pool: number, chainid: number | undefined): Promise<any> {
    const response = await fetch('/api/pools/send-payment-hash/', {
        method: 'POST',
        body: JSON.stringify({ transactionHash, pool, chainid }),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    if (!response.ok) {
        throw new Error('Error sending payment data to API');
    }
    return await response.json();
}

export async function sendPaymentDataToApi(transactionFrom: any, transactionTo: any, transactionHash: any, pool: number, chainid: number | undefined, spezialpool: boolean, iswflr: boolean): Promise<any> {
    const response = await fetch('/api/pools/send-payment-data/', {
        method: 'POST',
        body: JSON.stringify({ transactionFrom, transactionTo, transactionHash, pool, chainid, spezialpool, iswflr }),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Error sending payment data to API');
    }
    return await response.json();
}
