'use client';
import {prepareSendTransaction, prepareWriteContract, sendTransaction, writeContract} from "@wagmi/core";
import { waitForTransaction } from 'wagmi/actions'
import { parseEther, TransactionExecutionError, EstimateGasExecutionError, ContractFunctionExecutionError } from 'viem'
import {toast} from "react-toastify";
import { sendPaymentDataToApi, sendTransactionHash } from "./api";

interface BatchDelegateParams {
    delegatees: string[];
    bips: number[];
    chainId: number | undefined;
}

function getContractAddress(chainId: number | undefined) {
    if (chainId === 14) {
        return "0x1d80c49bbbcd1c0911346656b529df9e5c2f783d";
    } else if (chainId === 16) {
        return "0x1659941d425224408c5679eeef606666c7991a8a";
    } else if (chainId === 19) {
        return "0x02f0826ef6ad107cfc861152b32b52fd11bab9ed";
    } else if (chainId === 114) {
        return "0xc67dce33d7a8efa5ffeb961899c73fe01bce9273";
    }

    return "0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d";
}


export async function batchDelegate(params: BatchDelegateParams) {
    const { delegatees, bips, chainId } = params;

    let ContractAddress: `0x${string}`;
    ContractAddress = getContractAddress(chainId);

    const abi: any[] = [
        {
            name: 'batchDelegate',
            type: 'function',
            stateMutability: 'nonpayable',
            inputs: [
                { internalType: 'address[]', name: '_delegatees', type: 'address[]' },
                { internalType: 'uint256[]', name: '_bips', type: 'uint256[]' },
            ],
            outputs: [],
        },
    ];

    const { hash } = await writeContract({
        address: ContractAddress,
        abi: abi,
        functionName: 'batchDelegate',
        args: [delegatees, bips],
    });

    const toastId = toast.info('Transaction in process.', {
        position: "bottom-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    await waitForTransaction({ confirmations: 2, hash: hash });

    toast.update(toastId, {
        render: 'Transaction completed.',
        autoClose: 5000,
        type: toast.TYPE.SUCCESS
    });

}

export async function Wrapcoins(sum: number, chainid: number) {
    try {
        let ContractAddress: `0x${string}`;
        ContractAddress = getContractAddress(chainid);

        const value = parseEther(sum.toString() as `${number}`);
        const abi: any[] = [
            {
                name: 'deposit',
                type: 'function',
                stateMutability: 'nonpayable',
                inputs: [],
                outputs: [],
            },
        ];

        // @ts-ignore
        const { hash } = await writeContract({
            address: ContractAddress,
            abi: abi,
            functionName: 'deposit',
            args: [],
            value: value
        });

        const toastId = toast.info('Transaction in process.', {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        await waitForTransaction({ confirmations: 2, hash: hash });

        toast.update(toastId, {
            render: 'Transaction completed.',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS
        });
    } catch (error: any) {
        console.log(error)
        handleTransactionError(error);
    }
}
export async function rewardesClaim(address: (`0x${string}` & {}) | `0x${string}` | undefined, chainid: number, epoch: number, wrap: boolean) {
    try {
        let contractadress: `0x${string}` = "0x";
        if(chainid == 14) {
            contractadress = "0x85627d71921AE25769f5370E482AdA5E1e418d37";
        } else if(chainid == 16) {
            contractadress = "0x1659941d425224408c5679eeef606666c7991a8a";
        } else if(chainid == 19) {
            contractadress = "0x02f0826ef6ad107cfc861152b32b52fd11bab9ed";
        } else if(chainid == 114) {
            contractadress = "0xc67dce33d7a8efa5ffeb961899c73fe01bce9273";
        }

        const abi: any[] = [
            {
                name: 'claim',
                type: 'function',
                stateMutability: 'nonpayable',
                inputs: [
                    { internalType: 'address', name: '_rewardOwner', type: 'address' },
                    { internalType: 'address', name: '_recipient', type: 'address' },
                    { internalType: 'uint256', name: '_rewardEpoch', type: 'uint256' },
                    { internalType: 'bool', name: '_wrap', type: 'bool' },
                ],
                outputs: [],
            },
        ];


        const { hash } = await writeContract({
            address: contractadress,
            abi: abi,
            functionName: 'claim',
            args: [address, address, epoch, wrap],
        });

        const toastId = toast.info('Transaction in process.', {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        await waitForTransaction({ confirmations: 2, hash: hash });

        toast.update(toastId, {
            render: 'Transaction completed.',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS
        });

    } catch (error: any) {
        handleTransactionError(error);
    }
}
export async function Unwrapcoints(walletaddress: string, sum: number, chainid: number) {
    try {
        console.log(walletaddress);
        let ContractAddress: `0x${string}`;
        ContractAddress = getContractAddress(chainid);

        const value = parseEther(sum.toString() as `${number}`);

        const abi: any[] = [
            {
                name: 'withdraw',
                type: 'function',
                stateMutability: 'nonpayable',
                inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }],
                outputs: [],
            },
        ];

        const {hash} = await writeContract({
            address: ContractAddress,
            abi: abi,
            functionName: 'withdraw',
            args: [value],
        });

        const toastId = toast.info('Transaction in process.', {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        await waitForTransaction({ confirmations: 2, hash: hash });

        toast.update(toastId, {
            render: 'Transaction completed.',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS
        });
    } catch (error: any) {
        handleTransactionError(error);
    }
}

export async function sendTransactionToPool(Pool: number, chainId: number | undefined, isWrappedCoin: boolean, spezialPool: boolean, SenderAddress: string | undefined) {
    try {
        let RecipientAddress: `0x${string}` = "0x";
        let ContractAddress: `0x${string}` = "0x";
        let Amount: any = Pool;
        let isSpezialPool: boolean;
        isSpezialPool = Pool == 10000 && spezialPool;

        if (Number(Pool) === 100) {
            RecipientAddress = "0xEb13a43100B32aF72f93a56bdfdbE014ae243f96";
            Amount = parseEther(Pool.toString() as `${number}`);
        } else if (Number(Pool) === 250) {
            RecipientAddress = "0x9b553Ab273A335dE2eE6f11266b52a4F9d3ef3CB";
            //amount = ethers.utils.parseEther(String(pool));
            Amount = parseEther(Pool.toString() as `${number}`);
        } else if (Number(Pool) === 500) {
            RecipientAddress = "0x0b55e63B4c7077aADB2d303196205d66148b5990";
            Amount = parseEther(Pool.toString() as `${number}`);
        } else if (Number(Pool) === 750) {
            RecipientAddress = "0xC264F8Ec2f5141d666bb49B6D586f3cC3BDe711A";
            Amount = parseEther(Pool.toString() as `${number}`);
        } else if (Number(Pool) === 1000) {
            RecipientAddress = "0xF51224eAaeB895c55674080Dcb27985E38D044d7";
            Amount = parseEther(Pool.toString() as `${number}`);
        } else if (Number(Pool) === 2500) {
            RecipientAddress = "0x9339f8a2C78eEE8B0aC50b644C73c67Fc6bbD4cb";
            Amount = parseEther(Pool.toString() as `${number}`);
        } else if (Number(Pool) === 5000) {
            RecipientAddress = "0x1C5f2E86c0D83ce06088b6304355375F2F969512";
            Amount = parseEther(Pool.toString() as `${number}`);
        } else if (Number(Pool) === 7500) {
            RecipientAddress = "0xBe232Fb343496D777D44bfAE80CCbDeaa2E34BA6";
            Amount = parseEther(Pool.toString() as `${number}`);
        } else if (Number(Pool) === 10000) {
            RecipientAddress = "0x570D07B64154279cFfb642723E3c2AE626947FBB";
            Amount = parseEther(Pool.toString() as `${number}`);
        } else {
            toast.error('ERROR: Invalid pool selection!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            return console.error('Invalid pool selection');
        }


        if (chainId == 14 && isWrappedCoin) {
            ContractAddress = "0x1d80c49bbbcd1c0911346656b529df9e5c2f783d";
        } else if(chainId == 16 && isWrappedCoin) {
            ContractAddress = "0x1659941d425224408c5679eeef606666c7991a8a";
        } else if(chainId == 19 && isWrappedCoin) {
            ContractAddress = "0x02f0826ef6ad107cfc861152b32b52fd11bab9ed";
        } else if (chainId == 114 && isWrappedCoin) {
            ContractAddress = "0xc67dce33d7a8efa5ffeb961899c73fe01bce9273";
        }

        const check_response = await fetch('/api/pools/check-pool-active/', {
            method: 'POST',
            body: JSON.stringify({ Pool, chainId, address: SenderAddress }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const isActive = await check_response.json();

        if (isActive.status) {
            toast.error('Error: Pool is active', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            return;
        }

        if (isWrappedCoin) {
            try {
                const abi: any[] = [
                    {
                        name: 'transfer',
                        type: 'function',
                        stateMutability: 'nonpayable',
                        inputs: [{ internalType: 'address', name: 'recipient', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }],
                        outputs: [],
                    },
                ];

                const { request } = await prepareWriteContract({
                    address: ContractAddress,
                    abi: abi,
                    functionName: 'transfer',
                    args: [RecipientAddress, Amount],
                })
                const { hash } = await writeContract(request)

                const toastId = toast.info('Transaction in process.', {
                    position: "bottom-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                await sendTransactionHash(hash, Pool, chainId);

                const Transaction  = await waitForTransaction({ confirmations: 5, hash: hash, onReplaced: (transaction) => console.log(transaction), });

                const transactionHash = Transaction.transactionHash;
                const transactionFrom = Transaction.from;
                const transactionTo = Transaction.to;

                await sendPaymentDataToApi(transactionFrom, transactionTo, transactionHash, Pool, chainId, isSpezialPool, true);

                toast.update(toastId, {
                    render: 'Transaction Completed',
                    autoClose: 5000,
                    type: toast.TYPE.SUCCESS
                });


            } catch (error: any) {
                handleTransactionError(error);
            }
        } else {
            try {
                const request = await prepareSendTransaction({
                    to: RecipientAddress,
                    value: Amount,
                })

                const { hash } = await sendTransaction(request)

                const toastId = toast.info('Transaction in process..', {
                    position: "bottom-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                await sendTransactionHash(hash, Pool, chainId);

                const Transaction  = await waitForTransaction({ confirmations: 1, hash: hash, onReplaced: (transaction) => console.log(transaction), });

                const transactionHash = Transaction.transactionHash;
                const transactionFrom = Transaction.from;
                const transactionTo = Transaction.to;

                await sendPaymentDataToApi(transactionFrom, transactionTo, transactionHash, Pool, chainId, isSpezialPool, false);

                toast.update(toastId, {
                    render: 'Transaction Completed',
                    autoClose: 5000,
                    type: toast.TYPE.SUCCESS
                });

            } catch (error: any) {
                handleTransactionError(error);
            }
        }
    } catch (error: any) {
        console.log(error)
        handleTransactionError(error);
    }
}

function handleTransactionError(error: Error) {
    if (error instanceof TransactionExecutionError) {
        const errorMessage = error.message.split(':')[0].trim();
        toast.error('Error: ' + errorMessage, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    } else {
        if (error instanceof EstimateGasExecutionError) {
            const errorMessage = error.message.split(':')[0].trim();
            toast.error('Error: ' + errorMessage, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        } else {
            if (error instanceof ContractFunctionExecutionError) {
                const errorMessage = error.message.split(':')[2].trim();
                toast.error('Error: ' + errorMessage, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            } else {
                toast.error('Error: undefined', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                console.error(error);
            }
        }
    }
}

